import React, { useEffect, useRef } from 'react'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Delete } from '@material-ui/icons'

export default function CreditNoteItem (
  {
    idx,
    editingItem,
    editInvoiceItem,
    deleteInvoiceItem,
    setItem,
    isIssuerInVAT,
    useAdditionalTaxRate
  }) {

  const returnAmtRef = useRef()
  const totalAmtRef = useRef()
  const vatAmtRef = useRef()
  const noteRef = useRef()
  const shouldShowAdditionalTaxRate = useAdditionalTaxRate;

  useEffect(() => {
    if (editingItem.id) {
      returnAmtRef.current.value = Number(editingItem.returnAmt.toFixed(2))
      totalAmtRef.current.value = Number(editingItem.totalAmt).toFixed(2)
      vatAmtRef.current.value = Number(editingItem.vatAmt).toFixed(2)
    } else {
      returnAmtRef.current.value = 0
      totalAmtRef.current.value = 0
      vatAmtRef.current.value = 0
    }
  }, [editingItem.id, editingItem.vatRate, editingItem.returnAmt, editingItem.totalAmt])

  return (
    <Box>
      <Grid container spacing={2} style={{ border: '1px solid grey', borderRadius: '5px', marginBottom: '15px' }}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                multiline
                rows={1}
                rowsMax={4}
                variant="outlined"
                autoComplete="off"
                id="note"
                name="note"
                label="Opis"
                fullWidth
                size="small"
                inputProps={{ maxLength: 300 }}
                defaultValue={editingItem.note}
                disabled={!editingItem.id}
                inputRef={noteRef}
                onBlur={(e) => {
                  editingItem.update({
                    note: e.target.value
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <FormControl style={{ minWidth: '100%' }} size="small" variant="outlined">
            <InputLabel htmlFor="vatRate">PDV</InputLabel>
            <Select
              inputProps={{ tabIndex: -1 }}
              style={{ minWidth: '100%' }}
              labelId="vatRate"
              name="vatRate"
              id="vatRate"
              value={editingItem.vatRate}
              label="PDV"
              onChange={e => {
                editingItem.update({
                  vatRate: Number(Number(e.target.value).toFixed(0)),
                  vatAmt: Number(Number(editingItem.returnAmt * (Number(e.target.value) / 100)).toFixed(2)),
                  totalAmt: Number((editingItem.returnAmt * (1 + Number(e.target.value) / 100)).toFixed(2))
                })
                setItem(editingItem, idx)
                editInvoiceItem(editingItem, idx)
              }
              }
            >
              <MenuItem value={0}>0%</MenuItem>
              {isIssuerInVAT && <MenuItem value={7}>7%</MenuItem>}
              {shouldShowAdditionalTaxRate && isIssuerInVAT && <MenuItem value={15}>15%</MenuItem>}
              {isIssuerInVAT && <MenuItem value={21}>21%</MenuItem>}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                inputRef={returnAmtRef}
                variant="outlined"
                autoComplete="off"
                id="returnAmt"
                name="returnAmt"
                label="Povrat"
                fullWidth
                size="small"
                inputProps={{ min: 0, style: { textAlign: 'right' } }}
                defaultValue={0}
                onBlur={(e) => {
                  editingItem.update({
                    vatAmt: Number((Number(e.target.value) * (editingItem.vatRate / 100)).toFixed(2)),
                    returnAmt: Number(Number(e.target.value).toFixed(2)),
                    totalAmt: Number((Number(e.target.value) * (1 + editingItem.vatRate / 100)).toFixed(2))
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }}
                error={editingItem.returnAmt >=0}
              />
            </Grid>
            <Grid item xs>
              <TextField
                inputRef={vatAmtRef}
                variant="outlined"
                autoComplete="off"
                id="vatAmt"
                name="vatAmt"
                label="PDV"
                fullWidth
                size="small"
                inputProps={{ max: 0, style: { textAlign: 'right' } }}
                value={editingItem.vatAmt}
                disabled
              />
            </Grid>
            <Grid item xs>
              <TextField
                inputRef={totalAmtRef}
                variant="outlined"
                autoComplete="off"
                id="totalAmt"
                name="totalAmt"
                label="Ukupno"
                fullWidth
                size="small"
                inputProps={{ max: 0, style: { textAlign: 'right' } }}
                defaultValue={0}
                onBlur={(e) => {
                  const totalAmt = Number((Number(e.target.value)).toFixed(2));
                  const returnAmt = Number((Number(e.target.value) / (1 + editingItem.vatRate / 100)).toFixed(2));
                  const vatAmt = Number((totalAmt - returnAmt).toFixed(2));
                  editingItem.update({
                    vatAmt,
                    totalAmt,
                    returnAmt
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }}
                error={editingItem.totalAmt >=0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={1}>
          <Button
            tabIndex={-1}
            size="small"
            onClick={() => {
              deleteInvoiceItem(editingItem)
            }} variant="contained" color="default"><Delete/>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )

}