import React, { useCallback, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import MUIDataTable from 'mui-datatables'
import {
  Box, Button, Card, Dialog, DialogActions, DialogContent,
  IconButton, MenuItem, Select
} from '@material-ui/core'
import { useAxios } from '../../utils/hooks'
import { useHistory } from 'react-router'
import {
  getCurrentSubscriber,
  getCurrentTcr,
  getCurrentTcrData,
  getIsLoading,
  getInvoiceToPrint,
  setErrorSnackbar,
  setIsLoading,
  setInvoiceToPrint,
  setSuccessSnackbar, getInvoiceToPrintLocale
} from '../mainReducer'
import { useDispatch, useSelector } from 'react-redux'
import {
  Add,
  DoneOutline,
  HighlightOffOutlined,
  Print
} from '@material-ui/icons'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import 'moment/locale/me'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Autocomplete, Pagination } from '@material-ui/lab'
import CardContent from '@material-ui/core/CardContent'
import clsx from 'clsx'
import { InvoiceModel } from '../../models/InvoiceModel'
import CancelInvoiceDialog from './dialogs/CancelInvoiceDialog'
import {
  parseInvoiceTypeChip,
  parsePaymentMethod,
  parsePayMethodsChip, sleep,
  useInvoicesListStyles
} from '../../utils/helpers'
import { useDebounce } from 'use-debounce'
import Clear from '@material-ui/icons/Clear'
import InvoicesListItemMenu from '../../components/Menus/InvoicesListItemMenu'
import InvoicesPrintItemMenu from '../../components/Menus/InvoicesPrintItemMenu'
import packageJson from '../../../package.json'
import { CreditNoteModel } from '../../models/CreditNoteModel'

const rowsPerPage = 10

export default function Invoices () {
  const classes = useInvoicesListStyles()
  const history = useHistory()
  const [invoices, setInvoices] = useState([])
  const [invoicesCount, setInvoicesCount] = useState(0)
  const [from, setFrom] = useState(0)
  const [dateFrom, setDateFrom] = useState(moment().startOf('year').valueOf())
  const [dateTo, setDateTo] = useState(moment().endOf('day').valueOf())
  const [buyer, setBuyer] = useState(null)
  const [operator, setOperator] = useState(null)
  const [invOrdNum, setInvOrdNum] = useState('')
  const [debouncedInvOrdNum] = useDebounce(invOrdNum, 600)
  const [contractId, setContractId] = useState('')
  const [debouncedContractId] = useDebounce(contractId, 600)
  const [status, setStatus] = useState(null)
  const [invoiceType, setInvoiceType] = useState(null)
  const [invoiceTSType, setInvoiceTSType] = useState(null)
  const [stats, setStats] = useState(null)
  const dispatch = useDispatch()
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)
  const currentTcrData = useSelector(getCurrentTcrData)
  const isLoading = useSelector(getIsLoading)
  const invoiceToPrint = useSelector(getInvoiceToPrint)
  const invoiceToPrintLocale = useSelector(getInvoiceToPrintLocale)
  const [invoiceToCancel, setInvoiceToCancel] = useState(null)
  const [invoiceToRetry, setInvoiceToRetry] = useState(null)
  const [invoiceBytes, setInvoiceBytes] = useState(null)
  const [settings, setSettings] = useState({})
  const [invoicesListToPrint, setInvoicesListToPrint] = useState(false)

  const axiosInstance = useAxios()

  const getInvoices = useCallback(() => {
    dispatch(setIsLoading(true))
    const dateFromUrl = encodeURIComponent(moment(dateFrom).tz('Europe/Podgorica').format())
    const dateToUrl = encodeURIComponent(moment(dateTo).tz('Europe/Podgorica').format())
    if (!!axiosInstance.current && currentSubscriber && currentTcr) {
      axiosInstance.current.get(`report/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/invoice?from=${from}&size=${rowsPerPage}&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${buyer ? '&clientID=' + buyer.id : ''}${operator ? '&operatorCode=' + operator.operatorCode : ''}${debouncedInvOrdNum ? '&invOrdNum=' + debouncedInvOrdNum : ''}${debouncedContractId ? '&contractId=' + debouncedContractId : ''}&status=${!status ? 'SUCCESS,STALLED' : status.id}${invoiceType ? '&typeOfInv=' + invoiceType.id : ''}${invoiceTSType ? '&invoiceType=' + invoiceTSType.id : ''}`)
        .then(({ data }) => {
          setInvoices(data.invoices.map(i => {
            return {
              id: i.header ? i.header.uuid : '',
              id2: i.header ? i.header.uuid : '',
              id3: i.header ? i.header.uuid : '',
              id4: i.header ? i.header.uuid : '',
              invNum: i.invoice ? `${i.invoice.invOrdNum}/${i.invoice.yearOfIssuance}` : '',
              totPrice: i.invoice ? i.invoice.totPrice : '',
              issueDateTime: i.invoice ? i.invoice.issueDateTime : '',
              clientName: i.invoice && i.invoice.client ? i.invoice.client.name : '',
              discountAmount: i.invoice ? i.invoice.discountAmount : '',
              typeOfInv: i.invoice ? i.invoice.typeOfInv : '',
              corrected: i.header ? i.header.corrected || i.header.correctiveInv : false,
              invoiceType: i.invoice && i.invoice.invoiceType ? i.invoice.invoiceType : 'INVOICE',
              status: i.status,
              externalURL: i.response && i.response.body ? i.response.body.externalURL : (i.response && i.response.error ? i.response.error.externalURL : ''),
              jikr: i.response && i.response.body ? i.response.body.fic : '',
              ikof: i.response && i.response.body ? i.response.body.iic : '',
              payMethods: i.invoice ? i.invoice.payMethods : [],
              operator: i.invoice ? i.invoice.operatorCode : null,
              fullInvoice: i
            }
          }))
          setInvoicesCount(data.metadata.hits)
          setStats(data.stats)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [dispatch, axiosInstance, from, currentSubscriber, currentTcr, dateFrom, dateTo, buyer, operator, status, invoiceType, invoiceTSType, debouncedInvOrdNum, debouncedContractId])

  const getInvoiceBytes = async (invoiceId, locale) => {
    if (!!axiosInstance.current && currentSubscriber && currentTcr) {
      return await axiosInstance.current.get(`report/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/invoice/${invoiceId}/_print${locale ? '?locale=' + locale : ''}`, { responseType: 'blob' })
    }
  }

  const getInvoicesListBytes = async (dateFrom, dateTo, buyer, operator, status, invoiceType, invoiceTSType) => {
    const dateFromUrl = encodeURIComponent(moment(dateFrom).tz('Europe/Podgorica').format())
    const dateToUrl = encodeURIComponent(moment(dateTo).tz('Europe/Podgorica').format())
    if (!!axiosInstance.current && currentSubscriber && currentTcr) {
      return await axiosInstance.current.get(`report/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/invoice/_print?from=0&size=10000&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${buyer ? '&clientID=' + buyer.id : ''}${operator ? '&operatorCode=' + operator.operatorCode : ''}${debouncedInvOrdNum ? '&invOrdNum=' + debouncedInvOrdNum : ''}${debouncedContractId ? '&contractId=' + debouncedContractId : ''}&status=${!status ? 'SUCCESS,STALLED' : status.id}${invoiceType ? '&typeOfInv=' + invoiceType.id : ''}${invoiceTSType ? '&invoiceType=' + invoiceTSType.id : ''}`, { responseType: 'blob' })
    }
  }

  const byVat = stats && stats.byVATAmtTotal && stats.byVATAmtTotalApprovals ?
    [...stats.byVATAmtTotal, ...stats.byVATAmtTotalApprovals].reduce((map, curr) => {
      if (map[curr.vatRate]) {
        map[curr.vatRate] = {
          itemsCount: Number(map[curr.vatRate].itemsCount + curr.itemsCount),
          value: Number((map[curr.vatRate].value + curr.value).toFixed(2))
        }
        return map
      } else {
        map[curr.vatRate] = {
          itemsCount: Number(curr.itemsCount),
          value: Number(curr.value.toFixed(2))
        }
        return map
      }
    }, {}) : null

  useEffect(() => {
    getInvoices()
  }, [from, getInvoices, dateFrom, dateTo])

  useEffect(() => {
    const getTemplates = () => {
      dispatch(setIsLoading(true))
      if (!!axiosInstance.current) {
        axiosInstance.current.get(`/admin/subscriber/${currentSubscriber.id}/_settings`)
          .then((response) => {
            if (response) {
              const { data } = response
              if (data && data.content) {
                setSettings(data.content)
              }
            }
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      }
    }

    if (currentSubscriber.id) getTemplates()
  }, [axiosInstance, dispatch, currentSubscriber.id])

  useEffect(() => {
    if (invoiceToPrint) {
      dispatch(setIsLoading(true))

      getInvoiceBytes(invoiceToPrint, invoiceToPrintLocale)
        .then(({ data }) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = function () {
            const base64data = reader.result
            setInvoiceBytes(base64data)
          }
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    } else {
      setInvoiceBytes(null)
    }
  }, [invoiceToPrint, invoiceToPrintLocale])

  useEffect(() => {
    if (invoicesListToPrint) {
      dispatch(setIsLoading(true))

      getInvoicesListBytes(dateFrom, dateTo, buyer, operator, status, invoiceType, invoiceTSType)
        .then(({ data }) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = function () {
            const base64data = reader.result
            setInvoiceBytes(base64data)
          }
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    } else {
      setInvoiceBytes(null)
    }
  }, [invoicesListToPrint, dateFrom, dateTo, buyer, operator, status, invoiceType, invoiceTSType])

  useEffect(() => {
    console.log('invoiceToCancel', invoiceToCancel)
    if (invoiceToRetry) {
      retryInvoice(invoiceToRetry)
    }
  }, [invoiceToRetry])

  const columns = [
    {
      name: 'invNum',
      label: 'RB',
      options: {
        setCellProps: () => ({ style: { width: 100 } })
      },
    },
    {
      name: 'clientName',
      label: 'Partner',
      options: {},
    },
    {
      name: 'typeOfInv',
      label: 'Način plaćanja',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => value === 'CASH' ? 'Gotovinski' : 'Bezgotovinski'
      },
    },
    {
      name: 'invoiceType',
      label: 'Tip računa',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => parseInvoiceTypeChip(value)
      },
    },
    {
      name: 'payMethods',
      label: ' ',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => parsePayMethodsChip(value)
      },
    },
    {
      name: 'issueDateTime',
      label: 'Datum',
      options: {
        setCellProps: () => ({ style: { width: 90 } }),
        customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      name: 'operator',
      label: 'Operater',
      options: {
        setCellProps: () => ({ style: { width: 140 } }),
        customBodyRender: value => {
          if (currentTcr && currentTcr.users) {
            const user = currentTcr.users.find(u => u.operatorCode === value)
            if (user) {
              return user.name
            } else {
              return ''
            }
          } else {
            return ''
          }
        }
      },
    },
    {
      name: 'totPrice',
      label: 'Ukupno',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      },
    },
    {
      name: 'corrected',
      label: 'Kor',
      options: {
        display: 'excluded',
        width: 50,
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: (value) =>
          value ? <DoneOutline style={{ color: 'red' }}/> : null
      },
    },
    {
      name: 'status',
      label: 'F',
      options: {
        hint: 'Fiskalizovan',
        width: 50,
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({ style: { width: 50 } }),
        customBodyRender: (value) =>
          value === 'SUCCESS' ? <DoneOutline style={{ height: 16, color: 'green' }}/> :
            <HighlightOffOutlined style={{ height: 16, color: 'red' }}/>
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        width: 50,
        customBodyRender: (dataIndex) => {
          if (settings && settings.useMultiLanguageInvoices) {
            return <InvoicesPrintItemMenu invoiceId={dataIndex}/>
          } else {
            return (

              <IconButton
                color="primary"
                size="small"
                component="span"
                onClick={() => {
                  dispatch(setInvoiceToPrint({ invoiceToPrint: dataIndex }))
                }}>
                <Print/>
              </IconButton>
            )
          }
        },
        setCellProps: () => ({ style: { width: 50 } }),
      }
    },
    {
      name: 'id3',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        width: 50,
        customBodyRender: (dataIndex) => {
          const invoice = invoices.find(i => i.fullInvoice.header.uuid === dataIndex)
          return (
            <InvoicesListItemMenu invoice={invoice} setInvoiceToCancel={setInvoiceToCancel}
                                  setInvoiceToRetry={setInvoiceToRetry}/>
          )
        },
        setCellProps: () => ({ style: { width: 50 } }),
      }
    }
  ]

  const options = {
    filter: false,
    search: false,
    download: false,
    sort: false,
    pagination: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    serverSide: true,
    count: invoicesCount,
    rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: {},
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={() => history.push(`/invoices/new`)}>
            <Add/>
          </IconButton>
          <IconButton onClick={() => setInvoicesListToPrint(true)}>
            <Print/>
          </IconButton>
        </>
      )
    },
    setRowProps: (rowData) => {
      return {
        className: clsx({
          [classes.CorrectiveRow]: rowData[8]
        }),
        //style: { border: '3px solid blue' },
      }
    },
    textLabels: {
      body: {
        noMatch: 'Nema podataka',
        toolTip: 'Sortiraj',
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: 'Sledeća strana',
        previous: 'Prethodna strana',
        rowsPerPage: 'Redova po strani:',
        displayRows: 'od',
      },
      toolbar: {
        search: 'Pretraga',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setFrom(tableState.page * rowsPerPage)
          break
        case 'search':
          setFrom(tableState.page * rowsPerPage)
          break
        default:
      }
    },
  }

  const getInvoicesCount = useCallback(async () => {
    let invNumber = 0
    const url = `report/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/invoice/_max?status=SUCCESS,STALLED`

    if (!!axiosInstance.current) {
      dispatch(setIsLoading(true))
      await axiosInstance.current.get(url)
        .then(({ data }) => {
          if (data && data.maxOrdNum >= 0) {
            invNumber = Number(data.maxOrdNum)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }

    return invNumber + 1
  }, [axiosInstance, currentTcr.id, currentTcr.subscriberId, dispatch])

  const createCorrectiveInvoice = useCallback(async () => {
    if (isLoading || !invoiceToCancel || !invoiceToCancel.invoice) return

    let invoiceToCancelModel;
    if (invoiceToCancel.invoice.invoiceType === 'CREDIT_NOTE') {
      invoiceToCancelModel = new CreditNoteModel();
      invoiceToCancelModel.fromJSON(invoiceToCancel.invoice)
    } else {
      invoiceToCancelModel = new InvoiceModel();
      invoiceToCancelModel.fromJSON(invoiceToCancel.invoice)
    }

    const invOrdNum = await getInvoicesCount()
    if (!(invOrdNum > 0)) {
      return
    }
    const url = `request/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/_invoice`

    let invoiceToSend = invoiceToCancelModel.getCorrectiveInvoice(invoiceToCancel.response.body.iic)
    invoiceToSend.invOrdNum = invOrdNum

    const correctiveInv = {
      'iicRef': invoiceToCancel.response.body.iic,
      'issueDateTime': invoiceToSend.issueDateTime,
      'type': 'CORRECTIVE',
      'originalInvID': invoiceToCancel.header.uuid
    }

    const header = {
      'sendDateTime': moment().tz('Europe/Podgorica').format(),
      'subscriberID': currentTcr.subscriberId,
      'correctiveInv': correctiveInv,
      'tcrId': currentTcr.id,
      'certificateID': currentTcr.cacheInvoiceCert.id,
      'platform': 'web',
      'appVersion': packageJson.version
    }

    const body = { 'header': header, 'invoice': invoiceToSend }

    const validateStatus = (status) => {
      return status >= 200 && status < 303
    }

    dispatch(setIsLoading(true))
    if (!!axiosInstance.current) {
      axiosInstance.current.post(url, body, { validateStatus })
        .then(async ({ data }) => {
          const invoiceId = data.id
          const urlInvoiceResponse = `response/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/invoice/${invoiceId}`
          let i = 0
          while (i < 40) {
            await sleep(500).then(async () => {
              try {
                const response = await axiosInstance.current.get(urlInvoiceResponse)
                const rData = response.data

                if (rData && (rData.status === 'SUCCESS' || rData.status === 'STALLED' || rData.status === 'ERROR')) {
                  i = 40
                  if (rData.status === 'ERROR') {
                    dispatch(setErrorSnackbar(rData.response.error.faultString))
                  } else if (rData.status === 'SUCCESS') {
                    dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
                    history.push(`/invoices`)
                  } else if (rData.status === 'STALLED') {
                    dispatch(setSuccessSnackbar('Račun je sačuvan, ali nije fiskalizovan'))
                    history.push(`/invoices`)
                  }
                }
              } catch (e) {
                console.log('error getting invoice', e)
              }
            })
            i++
          }
        })
        .catch(err => {
          let errMessage = ''
          if (err && err.response && err.response.data) {
            console.log('err', err.response.data)
            if (err.response.data.msg) {
              errMessage = err.response.data.msg
            } else {
              errMessage = JSON.stringify(err.response.data)
            }
          } else {
            console.log('err', err)
          }
          dispatch(setErrorSnackbar('Greška: ' + errMessage))
        })
        .finally(() => {
          setInvoiceToCancel(null)
          dispatch(setIsLoading(false))
          getInvoices()
        })
    }
  }, [axiosInstance, dispatch, history, currentTcr, invoiceToCancel, getInvoicesCount, getInvoices])

  const retryInvoice = useCallback(async () => {
    if (isLoading || !invoiceToRetry || !invoiceToRetry.invoice || !invoiceToRetry.header || !invoiceToRetry.header.uuid) return

    const url = `request/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/_invoice`
    const urlInvoiceResponse = `response/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/invoice/${invoiceToRetry.header.uuid}`

    //first check if invoice is already in SUCCESS
    dispatch(setIsLoading(true))
    const response = await axiosInstance.current.get(urlInvoiceResponse)
    const rData = response.data

    if (rData && rData.status === 'SUCCESS') {
      dispatch(setIsLoading(false))
      dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
      getInvoices()
      return
    }

    if (rData && rData.status === 'IN_PROGRESS') {
      dispatch(setIsLoading(false))
      dispatch(setErrorSnackbar('Fiskalizacija ovog računa je u toku'))
      return
    }

    //continue...
    const header = {
      ...invoiceToRetry.header,
      'subseqDelivType': 'NOINTERNET',
      'platform': 'web',
      'appVersion': packageJson.version
    }

    const body = { 'header': header, 'invoice': invoiceToRetry.invoice }

    const validateStatus = (status) => {
      return status >= 200 && status < 303
    }

    if (!!axiosInstance.current) {
      axiosInstance.current.put(url, body, { validateStatus })
        .then(async () => {
          let i = 0
          while (i < 40) {
            await sleep(500).then(async () => {
              try {
                const response = await axiosInstance.current.get(urlInvoiceResponse)
                const rData = response.data

                if (rData && (rData.status === 'SUCCESS' || rData.status === 'STALLED' || rData.status === 'ERROR')) {
                  i = 40
                  if (rData.status === 'ERROR') {
                    dispatch(setErrorSnackbar(rData.response.error.faultString))
                  } else if (rData.status === 'SUCCESS') {
                    dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
                  } else if (rData.status === 'STALLED') {
                    dispatch(setSuccessSnackbar('Račun nije fiskalizovan'))
                  }
                }
              } catch (e) {
                console.log('error getting invoice', e)
              }
            })
            i++
          }
        })
        .catch(err => {
          dispatch(setErrorSnackbar(err?.response?.data?.msg || 'Greška'))
          console.log('err', err)
        })
        .finally(() => {
          setInvoiceToRetry(null)
          dispatch(setIsLoading(false))
          getInvoices()
        })
    }
  }, [axiosInstance, dispatch, currentTcr, invoiceToRetry, getInvoicesCount, getInvoices])

  return (
    <>
      <Box>
        <Grid container>
          <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
            <Grid item style={{ width: '175px' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                size="small"
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog-from"
                label="Datum od"
                format="DD.MM.yyyy."
                value={moment(dateFrom)}
                onChange={(value) => {
                  setFrom(0)
                  setDateFrom(moment(value).startOf('day').valueOf())
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={{ paddingRight: 5 }}
              />
            </Grid>
            <Grid item style={{ width: '175px' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                size="small"
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog-to"
                label="Datum do"
                format="DD.MM.yyyy."
                value={dateTo}
                onChange={(value) => {
                  setFrom(0)
                  setDateTo(moment(value).endOf('day').valueOf())
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item style={{ width: '220px' }}>
            <Autocomplete
              id="combo-box-buyer"
              size="small"
              fullWidth
              options={currentTcrData && currentTcrData.clients && currentTcrData.clients.length ? currentTcrData.clients.filter(c => !c.deleted).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setFrom(0)
                setBuyer(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Partner"
                           variant="outlined"/>}/>
          </Grid>
          <Grid item style={{ width: '220px' }}>
            <Autocomplete
              id="combo-box-operator"
              size="small"
              fullWidth
              options={currentTcr && currentTcr.users && currentTcr.users.length ? currentTcr.users : []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setFrom(0)
                setOperator(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Operater"
                           variant="outlined"/>}/>
          </Grid>
          <Grid item style={{ width: '200px' }}>
            <Autocomplete
              id="combo-box-status"
              size="small"
              fullWidth
              options={[
                { id: 'SUCCESS', name: 'Fiskalizovani' },
                { id: 'STALLED', name: 'Nefiskalizovani' },
                // {id: 'ERROR', name: 'Sa greskom'},
              ]}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setFrom(0)
                setStatus(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Status"
                           variant="outlined"/>}/>
          </Grid>
          <Grid item style={{ width: '200px' }}>
            <Autocomplete
              id="combo-box-type1"
              size="small"
              fullWidth
              options={[
                { id: 'CASH', name: 'Gotovinski' },
                { id: 'NONCASH', name: 'Bezgotovinski' },
              ]}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setFrom(0)
                setInvoiceType(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Način plaćanja"
                           variant="outlined"/>}/>
          </Grid>
          <Grid item style={{ width: '200px' }}>
            <Autocomplete
              id="combo-box-type-ts"
              size="small"
              fullWidth
              options={[
                { id: 'INVOICE', name: 'Račun' },
                { id: 'ADVANCE', name: 'Avans' },
                { id: 'CREDIT_NOTE', name: 'Knjižno odobrenje' },
              ]}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setFrom(0)
                setInvoiceTSType(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Tip fakture"
                           variant="outlined"/>}/>
          </Grid>
          <Grid item style={{ width: '180px' }}>
            <TextField
              id="standard-name"
              label="Redni broj"
              variant={'outlined'}
              size={'small'}
              style={{ marginTop: 16, width: '100%', paddingLeft: 5, paddingRight: 5 }}
              value={invOrdNum}
              onChange={(event) => {
                setInvOrdNum(event.target.value)
              }}
              InputProps={{
                endAdornment: invOrdNum ? <Clear style={{ color: 'grey', fontSize: 20, cursor: 'pointer' }}
                                                 onClick={() => setInvOrdNum('')}/> : null
              }}
            />
          </Grid>
          {settings && settings.contractLabel &&
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              id="standard-name"
              label={settings.contractLabel}
              variant={'outlined'}
              size={'small'}
              style={{ marginTop: 16, width: '100%', paddingLeft: 5, paddingRight: 5 }}
              value={contractId}
              onChange={(event) => {
                setContractId(event.target.value.replace(/\s/g, ''))
              }}
              InputProps={{
                endAdornment: contractId ? <Clear style={{ color: 'grey', fontSize: 20, cursor: 'pointer' }}
                                                  onClick={() => setContractId('')}/> : null
              }}
            />
          </Grid>}
        </Grid>
      </Box>
      {stats &&
      <Box display="flex" p={1}>
        <Box p={1} flexGrow={0}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.smallerPadding}>
              <Typography variant="h5" component="h2">
                {stats && stats.totalAmt && stats.totalAmt.value.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                UKUPNO
              </Typography>

            </CardContent>
          </Card>
        </Box>
        <Box p={1} flexGrow={0}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.smallerPadding}>
              <Typography variant="h5" component="h2">
                {stats && stats.discount && stats.discount.value.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                POPUST
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box p={1} flexGrow={8}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.smallerPadding}>
              <Grid container>
                {stats && stats.paymentMethodsSummary && stats.paymentMethodsSummary.length ?
                  stats.paymentMethodsSummary.sort((a, b) => a.paymentMethod - b.paymentMethod).map(pm =>
                    <Grid item xs key={pm.value}>
                      <Typography variant="h5" component="h2">
                        {pm.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </Typography>
                      <Typography className={classes.title} color="textSecondary"
                                  gutterBottom>
                        {parsePaymentMethod(pm.paymentMethod)}
                      </Typography>
                    </Grid>
                  )
                  :
                  null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box p={1} flexGrow={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.smallerPadding}>
              <Grid container>
                {byVat && Object.keys(byVat).length > 0 ?
                  Object.keys(byVat).map((vr, idx) =>
                    <Grid item xs key={`${byVat[vr].value}${idx}`}>
                      <Typography variant="h5" component="h2">
                        {byVat[vr].value.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </Typography>
                      <Typography className={classes.title} color="textSecondary"
                                  gutterBottom>
                        {`${vr}%`}
                      </Typography>
                    </Grid>
                  )
                  :
                  null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
      }
      <MUIDataTable
        title={
          <Typography variant="h6">
            Lista faktura
          </Typography>
        }
        data={invoices}
        columns={columns}
        options={options}
      />
      <Box display="flex" justifyContent="center" p={1}>
        {invoicesCount && invoicesCount / rowsPerPage > 0 ?
          <Box p={2}>
            <Select
              style={{ minWidth: '100%' }}
              labelId="page"
              name="page"
              id="page"
              value={Math.ceil(from / rowsPerPage)}
              onChange={e => {
                setFrom(rowsPerPage * e.target.value)
              }}
            >
              {[...Array(Math.ceil(invoicesCount / rowsPerPage)).keys()].map(i =>
                <MenuItem key={i} value={i}>{i + 1}</MenuItem>
              )}
            </Select>
          </Box> : null}
        <Box p={2}>
          <Pagination
            color="primary"
            showFirstButton
            showLastButton
            count={Math.ceil(invoicesCount / rowsPerPage)}
            page={Math.floor(from / rowsPerPage + 1)}
            onChange={(e, v) => {
              setFrom(rowsPerPage * (v - 1))
            }}
          />
        </Box>
        <Box p={3}>
          Ukupno: {invoicesCount}
        </Box>
      </Box>

      {invoiceBytes &&
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="lg"
        open={!!invoiceBytes}
        onClose={() => {
          dispatch(setInvoiceToPrint({ invoiceToPrint: null, invoiceToPrintLocale: undefined }))
          setInvoicesListToPrint(false)
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
          <object type="application/pdf" data={invoiceBytes}
                  style={{ flexGrow: 1, border: 'none', margin: 0, padding: 0 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setInvoiceToPrint({ invoiceToPrint: null, invoiceToPrintLocale: undefined }))
              setInvoicesListToPrint(false)
            }}
            variant="outlined" color="primary">
            Zatvori
          </Button>
        </DialogActions>
      </Dialog>}

      {invoiceToCancel && invoiceToCancel.invoice &&
      <CancelInvoiceDialog
        invoiceToCancel={invoiceToCancel}
        onClose={() => {
          setInvoiceToCancel(null)
        }}
        disabled={isLoading}
        createCorrectiveInvoice={createCorrectiveInvoice}
      />
      }
    </>

  )
}
