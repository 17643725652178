import { createSlice } from '@reduxjs/toolkit'
import { InvoiceModel } from '../models/InvoiceModel'

const resetInvoiceInState = (state) => {
  state.invoice = new InvoiceModel()
  state.invoiceTemplate = null
  state.invoiceTemplateFormData = {
    today: new Date(),
    currentYear: new Date().getFullYear()
  }
}

export const main = createSlice({
  name: 'main',
  initialState: {
    currentRoute: null,
    snackbar: {
      show: false,
      message: '',
      type: null
    },
    isLoading: false,
    user: null,
    currentRole: null,
    subscribers: [],
    currentSubscriber: null,
    currentStore: null,
    currentStoreTcrs: [],
    currentTcr: null,
    currentTcrData: null,
    dashboardReportData: null,
    adminDashboardData: null,
    dashboardReportPeriod: 0,
    dashboardLast7Days: [],
    invoiceToPrint: null,
    invoiceToPrintLocale: undefined,
    drawerOpened: localStorage.getItem('fiscall-drawer-opened') === 'true',
    subscribersFrom: 0,
    subscribersPerPage: 10,
    subscribersFilter: {
      accountant: null,
      seller: null
    },
    accountants: [],
    sellers: [],
    invoice: new InvoiceModel(),
    invoiceTemplate: null,
    invoiceTemplateFormData: {
      today: new Date(),
      currentYear: new Date().getFullYear()
    },

  },
  reducers: {
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload
    },
    setSnackbar: (state, action) => {
      state.snackbar = action.payload
    },
    setSuccessSnackbar: (state, action) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        type: 'success'
      }
    },
    setErrorSnackbar: (state, action) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        type: 'error'
      }
    },
    setWarningSnackbar: (state, action) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        type: 'warning'
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload
      state.subscribers = null
      state.currentStore = null
      state.currentSubscriber = null
      state.currentStoreTcrs = []
      state.currentTcr = null
      state.currentTcrData = null
    },
    setCurrentStore: (state, action) => {
      state.currentStore = action.payload
      state.currentStoreTcrs = []
      state.currentTcr = null
      state.currentTcrData = null
      resetInvoiceInState(state)
    },
    setCurrentStoreTcrs: (state, action) => {
      state.currentStoreTcrs = action.payload
      if (state.currentStoreTcrs && state.currentStoreTcrs.length === 1) {
        state.currentTcr = state.currentStoreTcrs[0]
      }
    },
    setSubscribers: (state, action) => {
      state.subscribers = action.payload
      if (state.subscribers && state.subscribers.length && state.subscribers.length === 1) {
        state.currentSubscriber = state.subscribers[0]
        const subscriber = state.subscribers[0]
        if (subscriber) {
          state.currentSubscriber = subscriber
          if (subscriber && subscriber.stores && subscriber.stores.length && subscriber.stores.length === 1) {
            state.currentStore = subscriber.stores[0]
          }
        }
      }
    },
    setCurrentSubscriber: (state, action) => {
      const subscriber = action.payload
      state.currentSubscriber = null
      state.currentStore = null
      state.currentStoreTcrs = []
      state.currentTcr = null
      state.currentTcrData = null
      resetInvoiceInState(state)
      if (subscriber) {
        state.currentSubscriber = subscriber
        if (subscriber && subscriber.stores && subscriber.stores.length && subscriber.stores.length === 1) {
          state.currentStore = subscriber.stores[0]
        }
      }
    },
    setCurrentTcr: (state, action) => {
      resetInvoiceInState(state)
      state.currentTcr = action.payload
    },
    setCurrentTcrData: (state, action) => {
      state.currentTcrData = action.payload
    },
    setAdminDashboardData: (state, action) => {
      state.adminDashboardData = action.payload
    },
    setDashboardReportData: (state, action) => {
      state.dashboardReportData = action.payload
    },
    setDashboardReportPeriod: (state, action) => {
      state.dashboardReportPeriod = action.payload
    },
    setDashboardLast7Days: (state, action) => {
      state.dashboardLast7Days = action.payload
    },
    setInvoiceToPrint: (state, action) => {
      if (action.payload) {
        state.invoiceToPrint = action.payload.invoiceToPrint
        state.invoiceToPrintLocale = action.payload.invoiceToPrintLocale
      }
    },
    setDrawerOpened: (state, action) => {
      localStorage.setItem('fiscall-drawer-opened', action.payload.toString())
      state.drawerOpened = action.payload
    },
    setSubscribersFrom: (state, action) => {
      state.subscribersFrom = action.payload
    },
    setSubscribersPerPage: (state, action) => {
      state.subscribersPerPage = action.payload
    },
    setSubscribersFilter: (state, action) => {
      state.subscribersFilter = action.payload
    },
    setCurrentTCRInventory: (state, action) => {
      state.currentTcr.inventory = action.payload
    },
    setCurrentTCRClients: (state, action) => {
      state.currentTcr.clients = action.payload
    },
    setAccountants: (state, action) => {
      state.accountants = action.payload
    },
    setSellers: (state, action) => {
      state.sellers = action.payload
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload
    },
    resetInvoice: (state) => {
      resetInvoiceInState(state)
    },
    setInvoiceTemplate: (state, action) => {
      state.invoiceTemplate = action.payload
    },
    setInvoiceTemplateFormData: (state, action) => {
      state.invoiceTemplateFormData = action.payload
    },
  },
})
//actions
export const {
  setCurrentRoute,
  setSnackbar,
  setSuccessSnackbar,
  setErrorSnackbar,
  setWarningSnackbar,
  setIsLoading,
  setUser,
  setCurrentRole,
  setSubscribers,
  setCurrentSubscriber,
  setCurrentStore,
  setCurrentStoreTcrs,
  setCurrentTcr,
  setCurrentTcrData,
  setDashboardReportData,
  setDashboardReportPeriod,
  setDashboardAutoRefreshPeriodInSec,
  setDashboardLast7Days,
  setInvoiceToPrint,
  setInvoiceToPrintLocale,
  setDrawerOpened,
  setSubscribersFrom,
  setSubscribersPerPage,
  setSubscribersFilter,
  setAdminDashboardData,
  setCurrentTCRInventory,
  setCurrentTCRClients,
  setAccountants,
  setSellers,
  setInvoice,
  resetInvoice,
  setInvoiceTemplate,
  setInvoiceTemplateFormData
} = main.actions

//selectors
export const getSnackbar = state => state.main.snackbar
export const getIsLoading = state => state.main.isLoading
export const getUser = state => state.main.user
export const getCurrentRole = state => state.main.currentRole
export const getSubscribers = state => state.main.subscribers
export const getCurrentSubscriber = state => state.main.currentSubscriber
export const getCurrentStore = state => state.main.currentStore
export const getCurrentStoreTcrs = state => state.main.currentStoreTcrs
export const getCurrentTcr = state => state.main.currentTcr
export const getCurrentTcrData = state => state.main.currentTcrData
export const getDashboardReportData = state => state.main.dashboardReportData
export const getDashboardReportPeriod = state => state.main.dashboardReportPeriod
export const getDashboardLast7Days = state => state.main.dashboardLast7Days
export const getInvoiceToPrint = state => state.main.invoiceToPrint
export const getInvoiceToPrintLocale = state => state.main.invoiceToPrintLocale
export const getDrawerOpened = state => state.main.drawerOpened
export const getSubscribersFrom = state => state.main.subscribersFrom
export const getSubscribersPerPage = state => state.main.subscribersPerPage
export const getSubscribersFilter = state => state.main.subscribersFilter
export const getAdminDashboardData = state => state.main.adminDashboardData
export const getAccountants = state => state.main.accountants
export const getSellers = state => state.main.sellers
export const getInvoice = state => state.main.invoice
export const getInvoiceTemplate = state => state.main.invoiceTemplate
export const getInvoiceTemplateFormData = state => state.main.invoiceTemplateFormData

export default main.reducer