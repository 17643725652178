import React, {useCallback, useEffect, useState} from 'react'
import {
    Box,
    IconButton,
} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {
    getAdminDashboardData,
    getCurrentRole,
    getIsLoading,
    getUser,
    setAdminDashboardData,
    setErrorSnackbar,
    setIsLoading,
    setSuccessSnackbar,
    setWarningSnackbar,
} from '../mainReducer'
import {
    DoneOutline,
    Replay
} from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import Typography from '@material-ui/core/Typography'
import { Pagination } from '@material-ui/lab'
import moment from 'moment-timezone'
import {
    calculateDateFrom,
    parseInvoiceTypeChip,
    parsePayMethodsChip, sleep,
    useDashboardInvoicesListStyles,
} from '../../utils/helpers'
import clsx from 'clsx'
import {useApi} from "../../api/api";
import {useAxios} from "../../utils/hooks";
import DashboardInvoicesSummary from "./DashboardInvoicesSummary";

const rowsPerPage = 10

const DashboardInvoices = ({dashboardReportPeriod, subscribersFilter}) => {
    const dispatch = useDispatch()
    const api = useApi()
    const axiosInstance = useAxios()
    const [invoiceToRetry, setInvoiceToRetry] = useState(null)
    const [counter, setCounter] = useState(0)
    const isLoading = useSelector(getIsLoading)
    const adminDashboardData = useSelector(getAdminDashboardData)
    const currentRole = useSelector(getCurrentRole)
    const user = useSelector(getUser)
    const [currentInvoiceType, setCurrentInvoiceType] = useState()
    const [invoices, setInvoices] = useState([])
    const [invoicesCount, setInvoicesCount] = useState(0)
    const [from, setFrom] = useState(0)
    const classes = useDashboardInvoicesListStyles()

    const columns = [
        {
            name: 'invNum',
            label: 'RB',
            options: {
                setCellProps: () => ({ style: { width: 70 } }),
            },
        },
        {
            name: 'sellerName',
            label: 'Firma',
            options: {
                customBodyRender: value => <span dangerouslySetInnerHTML={{ __html: value }}/>
            },
        },
        {
            name: 'typeOfInv',
            label: 'Način plaćanja',
            options: {
                setCellProps: () => ({ style: { width: 70 } }),
                customBodyRender: value => value === 'CASH' ? 'Gotovinski' : 'Bezgotovinski'
            },
        },
        {
            name: 'invoiceType',
            label: 'Tip računa',
            options: {
                setCellProps: () => ({ style: { width: 70 } }),
                customBodyRender: value => parseInvoiceTypeChip(value)
            },
        },
        {
            name: 'payMethods',
            label: ' ',
            options: {
                setCellProps: () => ({ style: { width: 70 } }),
                customBodyRender: value => parsePayMethodsChip(value)
            },
        },
        {
            name: 'issueDateTime',
            label: 'Datum',
            options: {
                customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm'),
                setCellProps: () => ({ style: { width: 100 } }),
            },
        },
        {
            name: 'totPrice',
            label: 'Ukupno',
            options: {
                display: currentRole && ['ADMIN'].includes(currentRole) ? 'true' : 'excluded',
                setCellProps: () => ({ style: { width: 70 } }),
                customBodyRender: value => value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
            },
        },
        {
            name: 'corrected',
            label: 'Kor',
            options: {
                display: 'excluded',
                customBodyRender: (value) =>
                    value ? <DoneOutline style={{ color: 'red' }}/> : null
            },
        },
        {
            name: 'platform',
            label: 'P/V',
            options: {
                hint: 'Platforma/Verzija',
                setCellProps: () => ({ style: { width: 80 } }),
            },
        },
        {
            name: 'errorCode',
            label: 'Code',
            options: {
                display: ["SUCCESS", "IN_PROGRESS"].includes(currentInvoiceType) ? 'excluded' : 'true',
                setCellProps: () => ({ style: { width: 60 } }),
            },
        },
        {
            name: 'error',
            label: 'Greška',
            options: {
                display: ["SUCCESS", "IN_PROGRESS"].includes(currentInvoiceType) ? 'excluded' : 'true',
                setCellProps: () => ({ style: { width: 400 } }),
                customBodyRender: (data) => {return <code style={{display: 'inline-block', overflowY: 'scroll', height:60}}>{data}</code>}
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                empty: true,
                width: 50,
                customBodyRender: (dataIndex) => {
                    const invoice = invoices.find(i => i.fullInvoice.header.uuid === dataIndex)
                    if (invoice && invoice.status === 'STALLED') {
                        return (
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => {
                                    setInvoiceToRetry(invoice.fullInvoice)
                                }}>
                                <Replay/>
                            </IconButton>
                        )
                    } else {
                        return null
                    }
                },
                setCellProps: () => ({ style: { width: 50 } }),
            }
        }
    ]

    const options = {
        filter: false,
        search: false,
        download: false,
        sort: false,
        pagination: false,
        print: false,
        viewColumns: false,
        responsive: 'standard',
        serverSide: true,
        count: invoicesCount,
        rowsPerPage,
        rowsPerPageOptions: [],
        sortOrder: {},
        selectableRows: 'none',
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        setRowProps: (rowData) => {
            return {
                className: clsx({
                    [classes.CorrectiveRow]: rowData[7]
                }),
            }
        },
        textLabels: {
            body: {
                noMatch: 'Nema podataka',
                toolTip: 'Sortiraj',
                columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
                next: 'Sledeća strana',
                previous: 'Prethodna strana',
                rowsPerPage: 'Redova po strani:',
                displayRows: 'od',
            },
            toolbar: {
                search: 'Pretraga',
                downloadCsv: 'Download CSV',
                print: 'Print',
                viewColumns: 'View Columns',
                filterTable: 'Filter Table',
            },
            filter: {
                all: 'All',
                title: 'FILTERS',
                reset: 'RESET',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
            selectedRows: {
                text: 'row(s) selected',
                delete: 'Delete',
                deleteAria: 'Delete Selected Rows',
            },
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setFrom(tableState.page * rowsPerPage)
                    break
                case 'search':
                    setFrom(tableState.page * rowsPerPage)
                    break
                default:
            }
        },
    }

    useEffect(() => {
        if (!axiosInstance.current || !['ADMIN', 'SELLER'].includes(currentRole)) {
            return;
        }

        dispatch(setIsLoading(true))

        const dateTo = moment().endOf('day').valueOf()
        const dateFrom = calculateDateFrom(dashboardReportPeriod)
        const dateFromUrl = encodeURIComponent(moment(dateFrom).tz('Europe/Podgorica').format())
        const dateToUrl = encodeURIComponent(moment(dateTo).tz('Europe/Podgorica').format())

        let sellerId

        switch (currentRole) {
            case 'ADMIN':
                sellerId = subscribersFilter.seller
                break
            case 'SELLER':
                sellerId = user.sub
                break
            default:

        }

        const getAdminReport = async () => {
            if (!!axiosInstance.current) {
                const adminDashData = {}
                await axiosInstance.current.get(`/report/invoice_summary?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${sellerId ? '&sellerId=' + sellerId : ''}`)
                    .then(({data}) => {
                        adminDashData.invoices = data.aggregations && data.aggregations.byStatus && data.aggregations.byStatus.buckets.length > 0 && data.aggregations.byStatus.buckets.reduce((map, obj) => {
                            map[obj.key] = obj.doc_count
                            return map
                        }, {})
                        if (data.aggregations && data.aggregations.totPriceSum && data.aggregations.totPriceSum.value !== undefined) {
                            adminDashData.totPriceSum = Number(data.aggregations.totPriceSum.value)
                        }
                        dispatch(setAdminDashboardData(adminDashData))
                    })
            }
        }

        getAdminReport().then(() => {
            api.getInvoices(from, 10, currentInvoiceType, dateFromUrl, dateToUrl, sellerId)
                .then(({ data }) => {
                    setInvoices(data.content.map(i => {
                        return {
                            id: i.header ? i.header.uuid : '',
                            invNum: i.invoice ? `${i.invoice.invOrdNum}/${i.invoice.yearOfIssuance}` : '',
                            issueDateTime: i.invoice ? i.invoice.issueDateTime : '',
                            totPrice: i.invoice ? i.invoice.totPrice : '',
                            sellerName: i.invoice && i.invoice.seller ? i.invoice.seller.name : '',
                            typeOfInv: i.invoice ? i.invoice.typeOfInv : '',
                            invoiceType: i.invoice && i.invoice.invoiceType ? i.invoice.invoiceType : 'INVOICE',
                            payMethods: i.invoice ? i.invoice.payMethods : [],
                            errorCode: i.response && i.response.error ? i.response.error.code : '',
                            error: i.response && i.response.error ? i.response.error.faultString : '',
                            corrected: i.header ? i.header.corrected || i.header.correctiveInv : false,
                            status: i.status,
                            platform: i.header && i.header.appVersion && i.header.platform ? `${i.header.platform}/${i.header.appVersion}` : '',
                            fullInvoice: i
                        }
                    }))
                    setInvoicesCount(data.header.totalItems)
                })
                .finally(() => {
                    dispatch(setIsLoading(false))
                })
        })


    }, [api, axiosInstance, from, currentInvoiceType, currentRole, subscribersFilter.seller, user, dashboardReportPeriod, counter])

    const retryInvoice = useCallback(async () => {
        if (isLoading || !invoiceToRetry || !invoiceToRetry.invoice || !invoiceToRetry.header || !invoiceToRetry.header.uuid || !invoiceToRetry.header.tcrId || !invoiceToRetry.header.subscriberID) return

        const tcrId = invoiceToRetry.header.tcrId
        const subscriberId = invoiceToRetry.header.subscriberID

        const url = `request/subscriber/${subscriberId}/tcr/${tcrId}/_invoice`
        const urlInvoiceResponse = `response/subscriber/${subscriberId}/tcr/${tcrId}/invoice/${invoiceToRetry.header.uuid}`

        //first check if invoice is already in SUCCESS
        dispatch(setIsLoading(true))
        try {
            const response = await axiosInstance.current.get(urlInvoiceResponse)
            const rData = response.data

            if (rData && rData.status === 'SUCCESS') {
                dispatch(setIsLoading(false))
                dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
                setCounter(counter + 1)
                return
            }

            if (rData && rData.status === 'IN_PROGRESS') {
                dispatch(setIsLoading(false))
                dispatch(setErrorSnackbar('Fiskalizacija ovog računa je u toku'))
                return
            }

            //continue...
            const header = {
                ...invoiceToRetry.header,
                'subseqDelivType': 'NOINTERNET'
            }

            const body = { 'header': header, 'invoice': invoiceToRetry.invoice }

            const validateStatus = (status) => {
                return status >= 200 && status < 303
            }

            if (!!axiosInstance.current) {
                axiosInstance.current.put(url, body, { validateStatus })
                  .then(async () => {
                      let i = 0
                      while (i < 40) {
                          await sleep(500).then(async () => {
                              try {
                                  const response = await axiosInstance.current.get(urlInvoiceResponse)
                                  const rData = response.data

                                  if (rData && (rData.status === 'SUCCESS' || rData.status === 'STALLED' || rData.status === 'ERROR')) {
                                      i = 40
                                      if (rData.status === 'ERROR') {
                                          dispatch(setErrorSnackbar(rData.response.error.faultString))
                                      } else if (rData.status === 'SUCCESS') {
                                          dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
                                      } else if (rData.status === 'STALLED') {
                                          dispatch(setWarningSnackbar('Račun je sačuvan, ali nije fiskalizovan'))
                                      }
                                  }
                              } catch (e) {
                                  console.log('error getting invoice', e)
                              }
                          })
                          i++
                      }
                  })
                  .catch(err => {
                      dispatch(setErrorSnackbar(err?.response?.data?.msg || 'Greška'))
                      console.log('err', err)
                  })
                  .finally(() => {
                      setInvoiceToRetry(null)
                      sleep(500)
                      dispatch(setIsLoading(false))
                      setCounter(counter + 1)
                  })
            }
        } catch (e) {
            setInvoiceToRetry(null)
            dispatch(setErrorSnackbar('Greška'))
            dispatch(setIsLoading(false))
        }
    }, [api, dispatch, invoiceToRetry])

    useEffect(() => {
        if (invoiceToRetry) {
            retryInvoice(invoiceToRetry)
        }
    }, [invoiceToRetry])

    return (
        <>
            <DashboardInvoicesSummary
                adminDashboardData={adminDashboardData}
                setCurrentInvoiceType={setCurrentInvoiceType}
                currentInvoiceType={currentInvoiceType}
            />

            <br/>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Lista faktura
                    </Typography>
                }
                data={invoices}
                columns={columns}
                options={options}
            />
            <Box display="flex" justifyContent="center" p={1}>
                <Box p={1}>
                    <Pagination
                        color="primary"
                        showFirstButton
                        showLastButton
                        count={Math.ceil(invoicesCount / rowsPerPage)}
                        page={Math.floor(from / rowsPerPage + 1)}
                        onChange={(e, v) => {
                            setFrom(rowsPerPage * (v - 1))
                        }}
                    />
                </Box>
                <Box p={2}>
                    Ukupno: {invoicesCount}
                </Box>
            </Box>
        </>
    )
}

export default DashboardInvoices